@import "./include/animate.css";
@import "./include/bootstrap/bootstrap";
// @import "./include/bootstrap.min.css";
@import "./font-awesome.min.css";
@import "./owlcarousel/owl.carousel.min.css";
@import "./owlcarousel/owl.theme.default.min.css";
@import "./include/jquery.scrollbar";
@import "./include/jssocials.css";
@import "./include/magnify.css";
@import "./include/select2.min.css";
@import "./gijgo.css";
@import "./include/toastr.min.css";
@import "./magnific-popup.css";
@import "./include/jquery-ui.css";
@import "./include/jquery.fancybox.min.css";